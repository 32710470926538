<template>
    <div v-if="ready == true" class="paginator-div do-not-print-me">
        <div>
            <div class="count-per-page">
                <div class="number-per-page">
                    Per page:
                    <select style="" @change="handlePageSizeChange">
                        <option
                            v-for="item in num_per_page_options"
                            :key="item"
                            :value="item"
                            :selected="resultsPerPage === item"
                        >
                            {{ item }}
                        </option>
                    </select>
                </div>
                <!--                <div class="total-count"><b>{{ total_count }}</b></div>-->
            </div>
            <nav aria-label="Page navigation">
                <ul class="chirp-table-pagination">
                    <li class="page-item" :class="currentPageNumber === 1 ? 'disabled' : 'active'">
                        <a class="page-link" href="#" @click="goToPage(1)">First</a>
                    </li>
                    <li class="page-item" :class="currentPageNumber === 1 ? 'disabled' : 'active'">
                        <a class="page-link" href="#" @click="goToPage(currentPageNumber - 1)">Previous</a>
                    </li>
                    <li v-for="(pageNumber, index) in numberedPages" :key="index" class="page-item">
                        <a
                            class="page-link"
                            :style="pageNumber === currentPageNumber ? 'color: blue;' : ''"
                            href="#"
                            @click="goToPage(pageNumber)"
                            >{{ pageNumber }}</a
                        >
                    </li>
                    <li class="page-item" :class="currentPageNumber >= totalPages ? 'disabled' : 'active'">
                        <a class="page-link" href="#" @click="goToPage(currentPageNumber + 1)">Next</a>
                    </li>
                    <li class="page-item" :class="currentPageNumber >= totalPages ? 'disabled' : 'active'">
                        <a class="page-link" href="#" @click="goToPage(totalPages)">Last</a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</template>

<script>
    /**
     * Pagination starts at the page 1, but the offset will always start off at 0 for DB purposes
     * i.e if you click page 1, and your page size is 10, and you have 100 results, your offset will be 0, your limit will be 10
     * and your total amount of pages will be 10.
     *
     */
    import { tryGetFilter } from '@/util/tryGetFilter';

    export default {
        props: {
            total_count: {
                type: Number,
                default: 0,
            },
            currentPage: {
                type: Number,
                default: 1,
            },
            pageSize: {
                type: [Number , String],
                default: 10,
            },
            storeKey: {
                type: String,
                default: 'invalid_key',
            },
            useOverride: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                totalItems: this.total_count,
                pageNeighbors: 3,
                ready: false,
                selectedPageSize: typeof this.pageSize === 'string' ? parseInt(this.pageSize) : this.pageSize,
                num_per_page_options: [10, 20, 50, 100],
            };
        },
        computed: {
            criteria() {
                return this.useOverride ? this.overrideCriteria : this.savedCriteria;
            },
            savedCriteria() {
                return tryGetFilter(this.$store, this.storeKey);
            },
            overrideCriteria() {
                return this.$store.getters['filters/overrideCriteria'](this.storeKey);
            },
            pageData() {
                return this.criteria.page;
            },
            resultsPerPage() {
                return this.pageData.num_per_page;
            },
            currentPageNumber() {
                return this.pageData.page_num;
            },
            numberedPages() {
                const startPage = Math.max(1, this.currentPageNumber - this.pageNeighbors) || 1;
                const endPage = Math.min(this.totalPages + 1, this.currentPageNumber + this.pageNeighbors + 1) || 2;
                return this.range(startPage, endPage);
            },
            totalPages() {
                return Math.ceil(this.totalItems / this.resultsPerPage);
            },
        },
        created() {
            this.$nextTick();
            this.ready = true;
        },
        methods: {
            handlePageSizeChange(e) {
                const newCriteria = { ...this.criteria };

                this.$emit('cancelOverride');

                newCriteria.page = {
                    page_num: 1,
                    num_per_page: parseInt(e.target.value),
                };

                this.$store.commit('filters/updateFilter', {
                    stateKey: this.storeKey,
                    criteria: newCriteria,
                });
            },
            goToPage(page) {
                if (page > 0 && page <= this.totalPages && page !== this.currentPageNumber) {
                    const newCriteria = { ...this.criteria };

                    this.$emit('cancelOverride');

                    newCriteria.page = {
                        ...newCriteria.page,
                        page_num: page,
                    };

                    this.$store.commit('filters/updateFilter', {
                        stateKey: this.storeKey,
                        criteria: newCriteria,
                    });
                }
            },
            range(start, stop, step = 1) {
                let begin = start;
                let end;
                if (stop == null) {
                    begin = 0;
                    end = start;
                } else {
                    end = stop;
                }

                return Array(Math.ceil(Math.abs((end - begin) / step)))
                    .fill(begin)
                    .map((x, y) => x + y * step);
            },
        },
        watch: {
            // This would be called anytime the value of title changes
            total_count(new_val, old_val) {
                if (new_val != old_val) {
                    this.totalItems = new_val;
                    // this.numberedPages()
                }
            },
        },
    };
</script>
