<template>
    <div v-if="ready == true">
        <div class="list-page-header do-not-print-me" v-if="!hideToolbar">
            <div class="list-header-left">
                <div class="breadcrumb">
                    <p>
                        <span v-if="pageMeta.title=='Clients'" class="page-title">Members</span>
                        <span v-else class="page-title">{{pageMeta.title}}</span>
                       
                    </p>
                </div>
                <!--                <div class="flex list-search" v-if="!hideSearch">-->
                <!--                    <input v-model="searchTextRaw" placeholder="Search" ref="searchInput" @blur="handleBlur" />-->
                <!--                    &lt;!&ndash; <button @click="search" type="button">Search</button> &ndash;&gt;-->
                <!--                </div>-->
            </div>

            <div class="list-header-right">
                <div class="reset-all-criteria" v-if="!hideReset">
                    <button @click="clear_criteria" type="button" class="no-bg reset-all-criteria-button">
                        Reset View
                    </button>
                </div>

                <!--                <div class="filter-wrap" v-if="!hideFilter">-->
                <!--                    <button @click="openFilter = !openFilter" type="button" class="no-bg filter-button">Filter</button>-->
                <!--                    <FilterList-->
                <!--                        :filters="JSON.parse(criteriaProps.filters)"-->
                <!--                        @getFilters="modifyCriteria"-->
                <!--                        @closeFilter="(close) => (this.openFilter = close)"-->
                <!--                        v-if="openFilter"-->
                <!--                        :selectedFilters="criteria.filter"-->
                <!--                        class="filters-container"-->
                <!--                        id="filter-menu"-->
                <!--                    />-->
                <!--                </div>-->

                <div class="hide-show-columns-button" v-if="!hideColumn">
                    <button @click="openColumn = !openColumn" type="button" class="no-bg">Columns</button>
                    <ColumnList
                        :selected_columns="criteria.column ? criteria.column : []"
                        @getColumns="modifyCriteria"
                        @closeColumn="(close) => (this.openColumn = close)"
                        v-if="openColumn"
                        :columns="hide_show_columns"
                        class="filters-container"
                        id="column-menu"
                    />
                </div>

                <div class="list-view-button" v-if="0 && !hideView">
                    <button class="no-bg">List View</button>
                </div>

                <div class="actions-wrap" v-if="!hideAction">
                    <button @click="show_hide_actions" class="actions-button no-bg">Actions</button>
                    <div class="actions-menu dropdown-menu" :class="actions_drop_down">
                        <div class="action-button">
                            <button class="no-bg" @click="export2exel">Export to Excel</button>
                        </div>
                        <div class="action-button">
                            <button class="no-bg" @click="export2csv">Export to CSV</button>
                        </div>
                        <div class="action-button">
                            <button class="no-bg" @click="printList">Print</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="speed-date-range-wrapper flex" v-if="!hide_speed_date">
            <SpeedDate
                :default_date_range="criteria.default_date_range"
                :useOverride="useOverride"
                :storeKey="storeKey"
            />
        </div>

        <div class="custom-list-grid-standard">
            <ejs-grid
                ref="grid"
                :data-source="formattedRowData"
                :allowFiltering="true"
                :allowSorting="true"
                :enableVirtualization="true"
                :rowSelected="onRowSelected"
                :actionComplete="actionHandler"
                :actionBegin="actionHandler"
                :sortSettings="sortOptions"
                :aria-rowcount="100"
                :allowPaging="false"
                :pageSettings="{ pageSize: 100 }"
                v-if="on"
            >
                <e-columns>
                    <e-column
                        v-for="column in formattedColumnMeta"
                        :key="column.field"
                        :field="column.field"
                        :headerText="column.headerText"
                        :allowSorting="column.allowSorting"
                        :editType="column.editType"
                        :type="column.type"
                        :displayAsCheckBox="column.displayAsCheckBox"
                        :textAlign="column.align"
                        :headerTextAlign="column.headerAlign"
                        :width="column.width"
                        :format="formatType(column.field)"
                    ></e-column>
                </e-columns>
            </ejs-grid>
        </div>
        <paginator
            :total_count="totalCount"
            v-if="!hidePaginator"
            :pageSize="criteria.page && criteria.page.num_per_page ? criteria.page.num_per_page : 10"
            :storeKey="storeKey"
            :useOverride="useOverride"
            @cancelOverride="$emit('cancelOverride')"
        />
    </div>
</template>

<script>
    import { tryGetFilter } from '@/util/tryGetFilter';

    function makeAssociative(assocCol, data) {
        return data.reduce((out, cur) => {
            out[cur[assocCol]] = cur;
            return out;
        }, {});
    }
    import Vue from 'vue';
    import { GridPlugin, Sort, VirtualScroll } from '@syncfusion/ej2-vue-grids';
    import Paginator from '@/components/general/paginator/paginator.vue';
    import FilterList from '@/components/general/filter/filter.vue';
    import ColumnList from '@/components/general/column/column.vue';
    import SpeedDate from '@/components/general/inputs/SpeedDate';
    import dayjs from '@/util/dayjs';
    import { debounce } from 'lodash';
    Vue.use(GridPlugin);

    export default {
        components: { Paginator, FilterList, ColumnList, SpeedDate },
        props: {
            data_source: {
                type: Array,
                default: () => []
            },
            export_report: {
                type: String,
                default: 'report'
            },
            totalCount: {
                type: Number,
                default: 0
            },
            column_meta: {
                type: Array,
                required: false
            },
            target_page: {
                type: String
            },
            loadingColumns: {
                type: Boolean
            },
            criteriaProps: {
                type: Object
            },
            createButton: {
                type: Boolean,
                default: false
            },
            hideSearch: {
                type: Boolean,
                default: false
            },
            hideFilter: {
                type: Boolean,
                default: false
            },
            hideColumn: {
                type: Boolean,
                default: false
            },
            hideView: {
                type: Boolean,
                default: false
            },
            hideAction: {
                type: Boolean,
                default: false
            },
            hideToolbar: {
                type: Boolean,
                default: false
            },
            hideReset: {
                type: Boolean,
                default: false
            },
            hidePaginator: {
                type: Boolean,
                default: false
            },
            hide_speed_date: {
                type: Boolean,
                default: true
            },
            preFormattedData: {
                type: Boolean,
                default: false
            },
            ignoreDateConversion: {
                type: Boolean,
                default: false
            },
            preformattedColumnNames: {
                type: Boolean,
                default: false
            },
            storeKey: {
                type: String,
                default: 'invalid_key'
            },
            useOverride: {
                type: Boolean,
                default: false
            }
        },
        name: 'ChirpReport',
        data() {
            return {
                canCreate: 1,
                searchBar: null,
                openFilter: false,
                openColumn: false,
                currentPage: 1,
                sort: {},
                ready: false,
                on: true,
                actions_drop_down: '',
                searchTextRaw: '',
                hide_show_columns: []
            };
        },
        computed: {
            criteria() {
                return this.useOverride ? this.overrideCriteria : this.savedCriteria;
            },
            savedCriteria() {
                return tryGetFilter(this.$store, this.storeKey);
            },
            overrideCriteria() {
                return this.$store.getters['filters/overrideCriteria'](this.storeKey);
            },
            sortOptions() {
                const sortKeys = Object.keys(this.criteria?.sort || {});

                return {
                    columns: sortKeys.map((key) => ({
                        field: key,
                        direction: this.criteria?.sort[key] || 'Ascending'
                    }))
                };
            },
            searchText() {
                return this.criteria?.search?.like_all || '';
            },
            formattedRowData() {
                if (this.preFormattedData) {
                    return this.data_source;
                }

                let formattedData = [...this.data_source];

                for (const [index, row] of Object.entries(formattedData)) {
                    for (const [column, value] of Object.entries(row)) {
                        let lowerColumn = column.toLowerCase();

                        if (
                            lowerColumn.includes('total') ||
                            lowerColumn.includes('amount') ||
                            lowerColumn.includes('cost') ||
                            lowerColumn.includes('price') ||
                            lowerColumn.includes('dollars')
                        ) {
                            if (typeof formattedData[index][column] === 'number') formattedData[index][column] = value / 100;
                        }

                        if (
                            (lowerColumn.includes('date') || lowerColumn.includes('dayt')) &&
                            !this.ignoreDateConversion
                        ) {
                            formattedData[index][column] = this.getShortDate(value);
                        }
                    }
                }

                return formattedData;
            },
            formattedColumnMeta() {
                return this.column_maker();
            }
        },
        methods: {
            column_maker() {
                const columnMeta = [];
                this.hide_show_columns = [];
                let rec2push, meta_man, ok2push;

                if (this.column_meta) {
                    meta_man = makeAssociative('field', this.column_meta);
                }

                if (this.data_source && this.data_source.length) {
                    const dataSourceModel = this.data_source[0];

                    for (const key in dataSourceModel) {
                        if (key !== 'id' && !this.criteria?.column?.includes(key)) {
                            ok2push = 1;
                            rec2push = {
                                field: key,
                                headerText: this.preformattedColumnNames ? key : this.convertStringToTitleCase(key),
                                allowFiltering: true,
                                allowSorting: true
                            };
                            if (meta_man && meta_man[key]) {
                                if (meta_man[key].hide) {
                                    ok2push = 0;
                                } else {
                                    for (const [k, v] of Object.entries(meta_man[key])) {
                                        rec2push[k] = v;
                                    }
                                }
                            }
                            if (ok2push) {
                                columnMeta.push(rec2push);
                            }

                            this.hide_show_columns.push({ label: rec2push.headerText, name: rec2push.field });
                        }
                    }
                } else {
                    if (this.column_meta) {
                        for (const metaObj of this.column_meta) {
                            if (metaObj.field !== 'id' && !this.criteria?.column?.includes(metaObj.field)) {
                                columnMeta.push(metaObj);
                            }
                        }
                    }
                }

                if (this.column_meta) {
                    this.hide_show_columns = this.column_meta
                        .filter((element) => {
                            if (element.field == 'id') return false;
                            return true;
                        })
                        .map((element) => {
                            return { label: element.headerText, name: element.field };
                        });
                }

                return columnMeta;
            },
            handlePageChange(newPage) {
                this.currentPage = newPage;
            },
            show_hide_actions() {
                if (this.actions_drop_down == '') {
                    this.actions_drop_down = 'show_actions_drop_down';
                } else {
                    this.actions_drop_down = '';
                }
            },
            convertStringToTitleCase(str) {
                str = str.toString().replaceAll('_', ' ');
                return (str + '').replace(/^([a-z])|\s+([a-z])/g, function($1) {
                    return $1.toUpperCase();
                });
            },
            closeFilter(data) {
                this.openFilter = data;
            },
            closeColumn(data) {
                this.openColumn = data;
            },
            formatType(field){
                if(field.includes('total') ||
                    field.includes('amount') ||
                    field.includes('cost') ||
                    field.includes('price') ||
                    field.includes('dollars')){
                    return 'C2';
                }
                return '';
            },
            autoFormatDataValues() {
                let result = this.data_source;
                if (result.length) {
                    if (this.criteria.column && this.criteria.column.length > 0) {
                        let columnsToHide = this.criteria.column;
                        result = this.data_source.map((element) => {
                            let elementTemp = {};
                            for (let key in element) {
                                if (columnsToHide.indexOf(key) == -1) {
                                    elementTemp[key] = element[key];
                                }
                            }
                            return elementTemp;
                        });
                    }

                    for (const [k, v] of Object.entries(result)) {
                        for (const [k2, v2] of Object.entries(v)) {
                            let field = k2.toLowerCase();
                            if (
                                field.includes('total') ||
                                field.includes('amount') ||
                                field.includes('cost') ||
                                field.includes('price') ||
                                field.includes('dollars')
                            ) {
                                result[k][k2] = this.$getCurrency(v2);
                            }
                            if ((field.includes('date') || field.includes('dayt')) && !this.ignoreDateConversion) {
                                result[k][k2] = this.getShortDate(v2);
                            }
                        }
                    }
                }
                return result;
            },
            getShortDate(isoDateString) {
                return dayjs(isoDateString).format('MM/DD/YY');
            },
            onRowSelected(args) {
                if (this.target_page) {
                    this.$router.push({ name: this.target_page, params: { record_id: args.data?.id?.toString() } });
                } else {
                    this.$emit('rowSelected', args.data);
                }
            },
            addNewRecord(args) {
                if (this.target_page) {
                    this.$router.push({ name: this.target_page, params: { record_id: 'new' } });
                } else {
                    this.$emit('addNewRecord', args.data);
                }
            },
            clear_criteria() {
                this.searchTextRaw = '';
                this.$emit('cancelOverride');
                this.$store.commit('filters/applyDefaultFilter', {
                    stateKey: this.storeKey,
                    defaultDateRange: this.criteria.default_date_range || 'YTD'
                });
            },
            //Handles actions
            actionHandler(args) {

                if (args.requestType == 'sorting' && args.type == 'actionBegin') {

                    if(typeof args.columnName !== 'undefined'){
                        this.modifyCriteria('sort', { header: args.columnName, direction: args.direction });
                    }
                }
            },
            search() {
                this.modifyCriteria('search', { term: this.searchBar });
            },
            applyColumns() {},
            debounceSearch: debounce(function() {
                const newCriteria = { ...this.criteria };
                this.$emit('cancelOverride');

                if (this.searchTextRaw) {
                    newCriteria.search = { like_all: this.searchTextRaw };
                } else {
                    newCriteria.search = {};
                }

                newCriteria.page = {
                    ...newCriteria.page,
                    page_num: 1
                };

                this.$store.commit('filters/updateFilter', {
                    stateKey: this.storeKey,
                    criteria: newCriteria
                });
            }, 500),
            async modifyCriteria(type, data) {
                const newCriteria = { ...this.criteria };
                switch (type) {
                    case 'column':
                        newCriteria.column = data;
                        this.doSort();
                        this.openColumn = false;

                        this.$emit('cancelOverride');

                        this.$store.commit('filters/updateFilter', {
                            stateKey: this.storeKey,
                            criteria: newCriteria
                        });

                        break;
                    case 'sort':
                        const { header, direction } = data;
                        const [sortKey] = Object.keys(newCriteria.sort);

                        // Do nothing if no sort currently applied and the sort command is empty
                        if (header === undefined && sortKey === undefined) {
                            break;
                        }

                        // Do nothing if list is already sorted in the way described by the sort command
                        if (header === sortKey && newCriteria.sort[sortKey] === direction) {
                            break;
                        }

                        if (data.header && data.direction) {
                            newCriteria.sort = { [data.header]: data.direction };
                        } else {
                            newCriteria.sort = {};
                        }

                        this.$emit('cancelOverride');
                        this.$store.commit('filters/updateFilter', {
                            stateKey: this.storeKey,
                            criteria: newCriteria
                        });

                        break;
                    case 'pagination':
                        const { num_per_page, page_num } = data;
                        newCriteria.page = { num_per_page, page_num };

                        this.$emit('cancelOverride');

                        this.$store.commit('filters/updateFilter', {
                            stateKey: this.storeKey,
                            criteria: newCriteria
                        });

                        break;
                    case 'filter':
                        this.openFilter = false;
                        newCriteria.filter = data;

                        this.$emit('cancelOverride');

                        this.$store.commit('filters/updateFilter', {
                            stateKey: this.storeKey,
                            criteria: newCriteria
                        });

                        break;
                    case 'search':
                        if (data.term) {
                            newCriteria.search = { like_all: data.term };
                        } else {
                            newCriteria.search = {};
                        }

                        this.$emit('cancelOverride');

                        this.$store.commit('filters/updateFilter', {
                            stateKey: this.storeKey,
                            criteria: newCriteria
                        });

                        break;
                    case 'date_range':
                        newCriteria.date_range = data;

                        this.$emit('cancelOverride');

                        this.$store.commit('filters/updateFilter', {
                            stateKey: this.storeKey,
                            criteria: newCriteria
                        });

                        break;
                    default:
                        break;
                }
            },

            getStoreData() {
                this.searchTextRaw = this.searchText;
                this.ready = true;
            },
            doSort() {
                this.sort = this.criteria.sort;
                let sortTemp = null;
                if (typeof this.sort == 'object' && Object.keys(this.sort).length !== 0) {
                    sortTemp = this.sort;
                }
                if (sortTemp == null) {
                    this.sortOptions = {};
                } else {
                    let direction = sortTemp[Object.keys(sortTemp)[0]];
                    let field = Object.keys(sortTemp)[0];
                    this.sortOptions = { columns: [{ field: field, direction: direction }] };
                }
            },
            handleBlur(e) {
                if (this.searchTextRaw || e.relatedTarget?.classList?.contains('e-focus')) {
                    this.$refs.searchInput?.focus();
                }
            },
            async export2exel() {
                await this.exportApi('xls');
            },
            async export2csv() {
                await this.exportApi('csv');
            },
            async exportApi(type) {
                if (this.export_report && this.export_report != 'report') {
                    const exp_source = await this.export_body();

                    if (exp_source) {
                        if (exp_source[0]) {
                            this.exportSource(exp_source, type);
                        }
                    }
                } else {
                    //Default source if no export prop is found
                    if (this.data_source[0]) {
                        this.exportSource(this.data_source, type);
                    }
                }
            },
            exportSource(source, type) {
                let headers = Object.entries(source[0]);
                //Get column names of the initial criteria, excluding the id
                let c_meta = this.column_meta
                    .filter((v) => !(v['field'] === "id" || (('hide' in v) && v.hide)))
                    .map((v) => v['field']);
                //Get and filter column headers based on current criteria
                let csv = headers
                    .filter((v) => (c_meta.includes(v[0]) && !this.criteria?.column?.includes(v[0])))
                    .map((v) => v[0])
                    .join((type === 'xls') ? '\t' : ',') + '\n';
                //List rows of results based on current and filtered criteria
                source.forEach((row) => {
                    csv +=
                        Object.entries(row)
                            .filter((v) => (c_meta.includes(v[0]) && !this.criteria?.column?.includes(v[0])))
                            .map((v) => v[1])
                            .join((type === 'xls') ? '\t' : ',') + '\n';
                });
                const format_type = (type === 'xls') ? 'application/vnd.ms-excel;charset=utf-8' : 'text/csv;charset=utf-8';
                this.export2file(csv, format_type, type);
            },
            export2file(content, data_type, ext) {
                const anchor = document.createElement('a');
                anchor.href = 'data:' + data_type + ',' + encodeURIComponent(content);
                anchor.target = '_blank';
                anchor.download = this.storeKey + '.' + ext;
                anchor.click();
            },
            printList() {
                window.print();
            },
            update_criteria_props(newVal, oldVal) {
                // if (this.target_page) {
                //     this.$store.commit('filters/update', {
                //         criteriaPage: this.target_page,
                //         payload: this.criteria,
                //     });
                // }
            },
            async export_body() {
                const body = {
                    criteria: {
                        ...this.criteria,
                        report: this.export_report
                    },
                };
                const body2 = JSON.parse(JSON.stringify(body));
                body2.criteria.page.num_per_page = 10000;
                body2.criteria.page.page_num = 1;

                try {
                    const res2 = await this.$api.post('/reports', body2);
                    return res2.data.rows;
                } catch (e) {
                    //Error: Invalid Api Root
                }

                return null;
            }
        },
        created() {
            this.getStoreData();
        },
        provide: {
            grid: [Sort, VirtualScroll]
        },
        watch: {
            searchTextRaw() {
                this.debounceSearch();
            },
        }
    };
</script>

<style>
    div.list-header-right div.actions-wrap div.show_actions_drop_down {
        visibility: visible;
    }
</style>
