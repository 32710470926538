<template>
    <div v-if="loading == false">
        <ChirpReport
            :data_source="rows"
            :export_report="export_report"
            :target_page="targetPage"
            :loadingColumns="loadingTable"
            :pre-formatted-data="true"
            :column_meta="columnMeta"
            :totalCount="totalCount"
            :hide_speed_date="false"
            :storeKey="storeKey"
            :useOverride="useOverride"
            @cancelOverride="useOverride = false"
        />
    </div>
</template>
<script>
    import { tryGetFilter } from '@/util/tryGetFilter';

    const api_root = 'reports';
    const report = 'therapist_income';
    import ChirpReport from '@/components/general/list/ChirpReport';

    export default {
        name: 'TherapistIncome',
        components: { ChirpReport },
        props: {
            overrideFilter: {
                type: Object,
                default: null
            }
        },
        data() {
            return {
                rows: [],
                targetPage: '',
                useOverride: false,
                totalCount: 0,
                loading: true,
                loadingTable: false,
                criteriaProps: {},
                columnMeta: [
                    { field: 'name', headerText: 'Clinician', allowSorting: true },
                    { field: 'user_status', headerText: 'Status', allowSorting: true },
                    { field: 'primary_clients', headerText: 'Primary Clients', allowSorting: true, align: 'Right' },
                    { field: 'num_clients_served', headerText: 'Clients Served in Date Range', allowSorting: true, align: 'Right' },
                    { field: 'num_appts', headerText: 'Appointments in Date Range', allowSorting: true, align: 'Right' },
                    { field: 'total_client_payments', headerText: 'Income', allowSorting: true, align: 'Right' },
                    // { field: 'total_company_income', headerText: 'Total Company Payments', allowSorting: true, align: 'Right' },
                    // { field: 'percentage_total_income', headerText: 'Percentage of Total Income', allowSorting: true, hidden: true }
                ],
                export_report: 'therapist_income'
            };
        },
        computed: {
            criteria() {
                return this.useOverride && this.overrideFilter ? this.overrideCriteria : this.savedCriteria;
            },
            savedCriteria() {
                return tryGetFilter(this.$store, this.storeKey);
            },
            overrideCriteria() {
                return this.$store.getters['filters/overrideCriteria'](this.storeKey);
            },
            storeKey() {
                return this.pageMeta?.page || 'therapistIncome';
            }
        },
        methods: {
            // Needs column meta - API not returning any results currently, so can't add until that's sorted.
            async load_data(criteria = this.criteria) {
                const body = {
                    criteria: {
                        ...criteria,
                        report
                    }
                };
                const res = await this.$api.post(`/${api_root}`, body);
                this.rows = res.data.rows.map((row) => {
                    const clientPayments = row.total_client_payments
                        ? this.$getCurrency(row.total_client_payments)
                        : this.$getCurrency(0);
                    // const percentageTotalIncome = new Intl.NumberFormat('en-US', {
                    //     style: 'percent',
                    //     maximumFractionDigits: 2
                    // }).format(row.percentage_total_income || 0);

                    return {
                        name: row.name,
                        user_status: row.user_status,
                        primary_clients: row.primary_clients,
                        num_clients_served:row.num_clients_served,
                        num_appts: row.num_appts,
                        total_client_payments: clientPayments,
                        // percentage_total_income: percentageTotalIncome
                    };
                });
                this.totalCount = res.data.total_count;

                if (
                    !criteria?.default_date_range ||
                    criteria?.default_date_range !== res.data.default_date_range
                ) {
                    this.$store.commit('filters/updateFilter', {
                        stateKey: this.storeKey,
                        criteria: {
                            ...criteria,
                            default_date_range: res.data.default_date_range,
                        }
                    });
                }

                this.$nextTick(() => {
                    this.loading = false;
                });
            },
            async init() {
                if (this.overrideFilter) {
                    this.useOverride = true;
                    this.$store.commit('filters/applyOverride', {
                        stateKey: this.storeKey,
                        criteria: this.overrideFilter
                    });
                }

                await this.load_data();
            },
            async modifyCriteria(criteria) {
                if (this.targetPage) {
                    this.$store.commit('filters/update', {
                        criteriaPage: this.targetPage,
                        payload: criteria
                    });
                }
                this.criteriaProps = criteria;
                await this.load_data();
            }
        },
        async created() {
            await this.init();
        },
        watch: {
            criteria: {
                deep: true,
                async handler(newVal, oldVal) {
                    let updatedNewVal = { ...newVal };

                    if (Object.keys(newVal.sort).length === 0){
                        updatedNewVal.sort = oldVal.sort;
                    }

                    const newStr = JSON.stringify(updatedNewVal);
                    const oldStr = JSON.stringify(oldVal);
                    if (newStr !== oldStr) {
                        await this.load_data(updatedNewVal);
                    }
                },
            }
        }
    };
</script>
