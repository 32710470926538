<template>
    <div>
        <h3 class="nowrap">Hide/Show Columns</h3>
        <div v-for='(column, columnIndex) in s_columns' :key='column.name' class='flex center bottom-5'>
            <input type='checkbox' class="color" :id="'column_box' + columnIndex" :value='column' v-model='selectedColumns'
            />
            <label :for="'column_box' + columnIndex">{{ column.label }}</label>
        </div>
        <button type='button' @click='applyColumns' class='apply-button'>Apply</button>
    </div>
</template>

<script>
    export default {
        props: {
            columns: {
                type: Array
            },
            selected_columns: {
                type: Array
            }
        },
        data() {
            return {
                selectedColumns: [],
                columns_mounted: false,
                /*Pass columns like this:
                name: your header name like last_name
                label: your header title like Last Name
                [{label: 'Last Name', name: 'last_name', checked: 0}, {label: 'First Name', name: 'first_name'}]
                */
                s_columns: this.columns ? this.columns.filter((c) => c.label) : []
            };
        },
        methods: {
            applyColumns() {
                let columns_to_hide = this.s_columns.filter((column) => {
                    //Don't add columns selected
                    if (this.selectedColumns.some((c) => column.name == c.name)) {
                        return false;
                    }

                    return true;
                }).map((element) => element.name);

                this.$emit(
                    'getColumns',
                    'column',
                    columns_to_hide
                );
            },
            closeColumns(event) {
                let specifiedElement = document.getElementById('column-menu');
                let isClickInside = specifiedElement.contains(event.target);
                if (!isClickInside) {
                    if(this.columns_mounted == false) {
                        this.$emit('closeColumn', false);
                    }else{
                        this.columns_mounted = false;
                    }
                }
            }
        },
        created() {
            this.selectedColumns = this.s_columns.filter((column) => {
                //Do not add that which needs to be hidden
                if (this.selected_columns.some((c) => column.name == c)) {
                    return false;
                }
                return true;
            });
        },
        mounted() {
            this.columns_mounted = true;
            document.addEventListener('click', this.closeColumns);
        },
        beforeDestroy() {
            document.removeEventListener('click', this.closeColumns);
        },
        watch: {

        }
    };
</script>

<style></style>
