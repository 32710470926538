export const tryGetFilter = (store, pageName) => {
    const filters = store.getters['filters/criteria'](pageName);

    if (filters) {
        return filters;
    }

    store.commit('filters/applyDefaultFilter', pageName);

    return store.getters['filters/criteria'](pageName);
};

export const tryGetPageData = (store, pageName) => {
    const filters = store.getters['filters/pageData'](pageName);

    if (filters) {
        return filters;
    }

    store.commit('filters/applyDefaultFilter', pageName);

    return store.getters['filters/pageData'](pageName);
};
