<template>
    <div class="speed-date bottom-15" v-if="1">
        <div class="report-period flex right-20">
            <label class="">Report Period</label>
            <select v-model="speed_date_val" @change="speed_date">
                <option
                    v-for="(k, v) in dates_dropdown"
                    :key="v"
                    :value="v"
                    :selected="v == speed_date_val"
                    :dummy2getknv="k"
                >
                    {{ ucwords(v) }}
                </option>
            </select>
        </div>
        <div class="date-range-pickers">
            <div class="start-date right-20">
                <Input
                    id="start_date"
                    type="date"
                    placeholder=""
                    :value="date_format(criteria.date_range.start_date)"
                    label="Start Date"
                    @change="custom_date"
                />
            </div>
            <div class="end-date">
                <Input
                    id="end_date"
                    type="date"
                    placeholder=""
                    :value="date_format(criteria.date_range.end_date)"
                    label="End Date"
                    @change="custom_date"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import { tryGetFilter } from '@/util/tryGetFilter';
    import dayjs from '@/util/dayjs';

    export default {
        name: 'SpeedDate',
        props: {
            useOverride: {
                type: Boolean,
                default: false,
            },
            storeKey: {
                type: String,
                default: 'invalid_store',
            },
        },
        data() {
            return {
                record_data: {
                    start_date: '',
                    end_date: '',
                },
                loading: true,
                speed_date_val: '',
            };
        },
        methods: {
            ucwords(str) {
                str = str.toString().replaceAll('_', ' ');
                return (str + '').replace(/^([a-z])|\s+([a-z])/g, function ($1) {
                    return $1.toUpperCase();
                });
            },
            async init() {
                this.speed_date_val = this.defaultDateRange;

                await this.$nextTick();

                const start_date = this.$utcConvert(this.dates_dropdown[this.speed_date_val].start);
                const end_date = this.$utcConvert(this.dates_dropdown[this.speed_date_val].end);

                if (this.speed_date_val && this.dates_dropdown[this.speed_date_val]) {
                    const dateRange = {
                        start_date,
                        end_date,
                    };

                    this.$store.commit('filters/updateFilter', {
                        stateKey: this.storeKey,
                        criteria: {
                            ...this.criteria,
                            date_range: dateRange,
                        },
                    });
                }

                this.loading = false;
            },
            async speed_date(e) {
                const v = e.target.value;
                const newDateRange = { ...this.criteria.date_range };

                if (v && this.dates_dropdown[v]) {
                    newDateRange.start_date = this.dates_dropdown[v].start;
                    newDateRange.end_date = this.dates_dropdown[v].end;
                }

                this.$store.commit('filters/updateFilter', {
                    stateKey: this.storeKey,
                    criteria: {
                        ...this.criteria,
                        date_range: newDateRange,
                    },
                });
            },
            async custom_date(e) {
                const newDate = e.value;
                const startOrEndKey = e.id;
                const newDateRange = { ...this.criteria.date_range };
                const acceptableKeys = ['start_date', 'end_date'];

                const newUTC = this.$utcConvert(newDate);

                if (startOrEndKey && acceptableKeys.includes(startOrEndKey)) {
                    this.speed_date_val = 'custom';
                    newDateRange[startOrEndKey] = newUTC;
                }

                if (newDateRange.end_date < newDateRange.start_date) {
                    newDateRange.end_date = newDateRange.start_date;
                }

                this.$store.commit('filters/updateFilter', {
                    stateKey: this.storeKey,
                    criteria: {
                        ...this.criteria,
                        date_range: newDateRange,
                    },
                });
            },
            date_format(date) {
                return dayjs(date).format('YYYY-MM-DD');
            },
        },
        computed: {
            criteria() {
                return this.useOverride && this.overrideFilter ? this.overrideCriteria : this.savedCriteria;
            },
            savedCriteria() {
                return tryGetFilter(this.$store, this.storeKey);
            },
            overrideCriteria() {
                return this.$store.getters['filters/overrideCriteria'](this.storeKey);
            },
            defaultDateRange() {
                return this.criteria?.default_date_range || 'YTD';
            },
            dates_dropdown() {
                let date2normal = (new_date) => {
                    let dd = String(new_date.getDate()).padStart(2, '0');
                    let mm = String(new_date.getMonth() + 1).padStart(2, '0'); // January is 0!
                    let yyyy = new_date.getFullYear();
                    return yyyy + '-' + mm + '-' + dd;
                };
                let mdy2normal = function (m, d, y) {
                    let mdy = new Date(y, m, d);
                    return date2normal(mdy);
                };
                let new_date = new Date();

                let m = new_date.getMonth();
                let d = new_date.getDate();
                let y = new_date.getFullYear();

                const $today = date2normal(new_date);
                const $yesterday = mdy2normal(m, d - 1, y);
                // const $tomorrow = mdy2normal(m, d + 1, y)

                return {
                    custom: '',
                    today: {
                        start: $today,
                        end: $today,
                    },
                    yesterday: {
                        start: $yesterday,
                        end: $yesterday,
                    },
                    // tomorrow     :  {
                    //     start : $tomorrow,
                    //     end   : $tomorrow,
                    // },
                    last_month: {
                        start: mdy2normal(m - 1, 1, y),
                        end: mdy2normal(m, 0, y),
                    },
                    current_month: {
                        start: mdy2normal(m, 1, y),
                        end: mdy2normal(m + 1, 0, y),
                    },
                    // next_month : {
                    //     start : mdy2normal(m + 1, 1, y),
                    //     end   : mdy2normal(m + 2, 0, y),
                    // },
                    two_months_ago: {
                        start: mdy2normal(m - 2, 1, y),
                        end: mdy2normal(m - 1, 0, y),
                    },
                    last_year: {
                        start: mdy2normal(0, 1, y - 1),
                        end: mdy2normal(11, 31, y - 1),
                    },
                    current_year: {
                        start: mdy2normal(0, 1, y),
                        end: mdy2normal(11, 31, y),
                    },
                    // next_year : {
                    //     start : mdy2normal(0, 1, y + 1),
                    //     end   : mdy2normal(11, 31, y + 1),
                    // },
                    MTD: {
                        start: mdy2normal(m, 1, y),
                        end: $today,
                    },
                    YTD: {
                        start: mdy2normal(0, 1, y),
                        end: $today,
                    },
                    quarter_1_current_year: {
                        start: mdy2normal(0, 1, y),
                        end: mdy2normal(2, 31, y),
                    },
                    quarter_2_current_year: {
                        start: mdy2normal(3, 1, y),
                        end: mdy2normal(5, 30, y),
                    },
                    quarter_3_current_year: {
                        start: mdy2normal(6, 1, y),
                        end: mdy2normal(8, 30, y),
                    },
                    quarter_4_current_year: {
                        start: mdy2normal(9, 1, y),
                        end: mdy2normal(11, 31, y),
                    },
                };
            },
        },
        async created() {
            await this.init();
        },
        watch: {
            criteria: {
                deep: true,
                async handler(newVal) {
                    if (!newVal.date_range?.start_date || !newVal.date_range?.end_date) {
                        await this.init();
                    }
                },
            },
        },
    };
</script>
